@import  url(https://cdnjs.cloudflare.com/ajax/libs/weather-icons/2.0.12/css/weather-icons.min.css);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  :root {
    --rad: 0.7rem;
    --dur: 0.3s;
    --color-dark: #2f2f2f;
    --color-light: #fff;
    --color-brand: #57bd84;
    --font-fam: "Lato", sans-serif;
    --height: 5rem;
    --btn-width: 6rem;
    --bez: cubic-bezier(0, 0, 0.43, 1.49);
  }
  
  body {
   
    background-image: linear-gradient(to bottom, #000000 , #052c3d) !important;
    height: 100vh;
   
    
    place-items: center;
  }
  
  .widget {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    height: 450px;
    width: 650px;
    flex-direction: column;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: 0 27px 55px 0 rgba(223, 220, 220, 0.3),
      0 17px 17px 0 rgba(251, 251, 251, 0.15);
  }
  .iconwrapper{
    display: flex;
    justify-content: center;
    padding: 50px;
  }
  .background{
    height: 100vh;
  }
.weatherIcon{
    font-size: 100px;
}
  .weatherInfo{
    display: flex;
  }
  .temperature{
    font-size: 50px;
  }
  .description{
    margin-left: 60px;
  }
  .weatherCondition{
    font-size: 40px;
    
  }
  .place{
    font-size: 15px;
  }
  
  /* input search button style  */
  
  .search{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 31px;
    
  }
  .searchTerm{
    padding: 10px;
    border: none;
  
    margin-left: 150px;
    border-radius: 10px 0 0 10px;
    
  }
  .searchTerm:active{
    border: none;
  }
  
  
  .searchButton{
    border-radius: 0px 10px 10px 0;
    margin-top: -1px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }

  
  /*Resize the wrap to see the search bar change!*/
  /* .wrap {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
  .row-2{
    display: flex;
  }

  .weatherInfo{
    background-color: rgba(0, 0, 0, 0.3);
    width: 70%;
    padding: 50px;
    font-size: 20px;
    color: white;
  }
  .date{
    background-color: #57bd84;
    width: 30%;
    padding: 50px;
    font-size: 20px;
    color: black;
  }

  .extra-temperature{
    display: flex;
    justify-content: space-between;
  }
  .twosided{
    width: 25%;
    display: flex;
    justify-content: space-around;
  }
  .left1{
    width: 30%;
    color: #57bd84;
  }
  .right1{
    width:70%;
    font-size: 12px;
    margin-left: 10px;
  }
  @media screen and  (max-width:1024px){
    body{
      height: 150vh;
    }
    .wrap{
      width:297px;
      display: flex;
      justify-content: center;

    }
    .background{
      width: 400px;
    }
    .weatherInfo{
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .description{
      margin: 0px;
    }
    .widget{
      width: 400px;
      height: 500px;
    }
    .date{
      background-color: #57bd84;
      width: 30%;
      padding: 150px 0 0 20px !important;
      font-size: 15px;
      color: black;
    }
  }

  @media (max-width:768px){
    .searchTerm{
      margin: 0;
    }
    .weatherInfo{
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .description{
      margin: 0px;
    }
    .date{
      background-color: #57bd84;
      width: 30%;
      padding: 150px 0 0 20px !important;
      font-size: 15px;
      color: black;
    }
    .widget{
      height: 520px;
    }
    .wrap{
      width:420px;
      display: flex;
      justify-content: center;
    }
  }
  @media(max-width:576px){
    .widget{
      width: 280px;
      margin-top: 50px;
    }
    .container{
      padding: 10px !important;
    }
    .background{
      width: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .wrap{
      width: 280px;
    
      display: flex;
      justify-content: center;
    }
    
    .date{
      font-size: 12px;
    }
    .search{
      display: flex;
      justify-content: center;
     flex-wrap: wrap;
      margin-bottom: 20px;
      height: 31px;
      
    }
    .searchTerm{
     
      padding: 10px;
      border: none;
    
     margin-bottom: 20px;
      border-radius: 10px;
      
    }
    .searchButton{
      border-radius: 10px;
      margin-top: -1px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left:70px;
    }
  }